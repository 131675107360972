/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

const Bio = ({ authorId }) => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      site {
        siteMetadata {
          authors {
            name
            summary
            id
            social {
              twitter
            }
          }
        }
      }
    }
  `);

  const { authors } = data.site.siteMetadata;
  const author = authors.find(author => author.id === authorId);
  if (!author) {
    return null;
  }
  return (
    <div className="flex my-3">
      <p>
        Escrito por {!author.social.twitter && author.name}
        {author.social.twitter && (
          <a href={`https://twitter.com/${author.social.twitter}`} target="_blank" rel="noreferrer noopener">
            {author.name}
          </a>
        )}
      </p>
    </div>
  );
};

export default Bio;
